import {KnownVariableMap, sanitizeVariables} from "./RewireVariables";
import {TransformationJob} from "../../Types/Transformation";
import {forAllParameterValues} from "../../Types/ComponentIterator";
import {tableInputImplementationId} from "./Rewirer";

export function unsanitizeTableName(tableName: string): string {
  return tableName.replace("COMPONENT_TABLE_", "")
}

export function unsanitizeTables(job: TransformationJob) {
  forAllParameterValues(job, (value, comp)=>{
    if(comp.implementationID !== tableInputImplementationId) {
      let newVal = unsanitizeTableName(value.value)
      if (newVal !== value.value) {
        value.value = newVal.replace(/(.*) - [\d]+$/, (a,b)=>b)
      }
    }
  })
}

export function sanitizeTableName(componentName: string): string {
  return `"COMPONENT_TABLE_${componentName}"`
}

export function sanitizeTableSyntax(knownVariables: KnownVariableMap, sql: string): string {
  let afterTables = sql.replace(/\$T{(.*?)}/g, (substring, tableName)=>{
    return sanitizeTableName(tableName)
  })
  let afterVars = sanitizeVariables(knownVariables, afterTables)
  return afterVars
}