import { Card, CardContent, CardHeader } from "@material-ui/core"
import ErrorDisplay from "../Common/ErrorDisplay"
import {ExportSqlObjects} from "./ExportedComponent"

function SuccessfulExport({name, sql, result}) {
    let {parseResult: {sqlObject}} = result

    return (
    <Card style={{minWidth: '40%', maxWidth: '50%', margin: '5px'}}>
        <CardHeader title={name} />
        <CardContent>
            <ExportSqlObjects sqlObjects={sqlObject} />
        </CardContent>
    </Card>)
}

export default function ExportResult({sql, result, name}) {
    let {error} = result
    if(error) {
        return <ErrorDisplay error={error} />
    } else {
        return <SuccessfulExport sql={sql} result={result} name={name}/>
    }
}