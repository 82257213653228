import { Button } from "@material-ui/core"
import ErrorDisplay from "../Common/ErrorDisplay"
import { useCallback, useContext, useState } from "react"
import { MetlCommunicationContext } from "../Communication/MetlCommunicator"

const NOT_STARTED = "NOT_STARTED"
const WAITING = "WAITING"
const ERRORED = "ERRORED"
const DONE = "DONE"

function StateIcon({state}) {
    return <div>{state}</div>
}

export default function MetlInstallButton({uiExport}) {
    let {metlInstance, comms} = useContext(MetlCommunicationContext)
    let [state, setState] = useState(NOT_STARTED)
    let [error, setError] = useState(null)
    let install = useCallback(()=>{
        setState(WAITING)
        setError(null)
        comms.post("InstallUIJobExport", uiExport, (data = {})=>{
            if(data.error) {
                setState(ERRORED)
                setError(data.error)
            } else {
                setState(DONE)
            }
        })
    }, [uiExport, comms])
    if(metlInstance) {
        return <div>
            <Button variant="contained" color="primary" onClick={install} disabled={state === WAITING}>
                <StateIcon state={state} />
                {state === WAITING ? `Switch to your ${metlInstance} tab to continue` : `Install to ${metlInstance}`}
            </Button>
            <ErrorDisplay error={error} />
        </div>
    } else {
        return null
    }
}