import {useCallback, useEffect, useState} from "react";
import {JobViewer} from "./JobViewer";
import {Radio} from "@material-ui/core";

function ExportedJob({selected, setSelected, sqlObject}) {
    let name = sqlObject.name || sqlObject.component.name
    let onClick= useCallback((e)=>{
        e.preventDefault()
        e.stopPropagation()
        setSelected(sqlObject)
    }, [sqlObject, setSelected])
    return <div style={{border: selected ? '1px solid green' : '1px solid black', margin: '5px'}} onClick={onClick}>
        <Radio checked={selected} />
        {name}
    </div>
}

export function ExportSqlObjects({sqlObjects}) {
    let [selected, setSelected] = useState(null)
    useEffect(()=>setSelected(null), [sqlObjects])
    let children = sqlObjects.map((sqlObject, i)=>{
        return <ExportedJob key={i} selected={sqlObject === selected} setSelected={setSelected} sqlObject={sqlObject}/>
    })
    return <div>
        {children}
        {selected ? <JobViewer component={selected.component} sql={selected.sql} /> : null }
    </div>
}