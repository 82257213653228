import './App.css';
import {MetlCommunicationWrapper} from './Communication/MetlCommunicator';
import AppInterface from "./Interface/AppInterface";
import {ModalProvider} from "./Common/ModalHelper";
import {AppBase} from "@matillion/octo-react-util";
import {Api} from "./Api/Api";

function App() {
    return (
        <AppBase
            apiProducer={(auth0, orgId) => new Api(auth0, orgId)}
            applicationName={"SQL Understander"}
        >
            <ModalProvider>
                <MetlCommunicationWrapper>
                    <AppInterface/>
                </MetlCommunicationWrapper>
            </ModalProvider>
        </AppBase>
    );
}

export default App;
