import {createContext, useCallback, useMemo, useState} from "react";
import {Modal, Button} from "@matillion/component-library";

export const ModalContext = createContext(null)

export function ModalProvider({children}) {
  let [open, setOpen] = useState(false)
  let [data, setData] = useState(null)
  let close = useCallback(()=>setOpen(false), [setOpen])
  let openDialog = useCallback((argsGetter)=>{
    setOpen(true)
    setData(argsGetter({close}))
  }, [setOpen, setData, close])
  let context = useMemo(()=>{
    return {
      notify: (text)=>openDialog(({close})=>({
        contentSlot: text,
        actionSlot: <Button text="Ok" onClick={close} />
      })),
      open: openDialog
    }
  }, [openDialog])
  return <ModalContext.Provider value={context}>
    {open && data ? <Modal {...data} onCancel={close} /> : null}
    {children}
  </ModalContext.Provider>
}