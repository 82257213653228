import {AppContents} from "./Transmogrifier/AppContents";
import {useState} from "react";
import SqlComponentUpdate from "./Transmogrifier/SqlComponentUpdate/SqlComponentUpdate";
import {Tab, Tabs} from "@material-ui/core";

export default function AppInterface() {
    let [tab, setTab] = useState(0)
    return (
        <>
            <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                <Tab value={0} label="Convert SQL"/>
                <Tab value={1} label="Convert Components"/>
            </Tabs>
            {tab === 0 ? <AppContents/> : null}
            {tab === 1 ? <SqlComponentUpdate/> : null}
        </>
    )
}