import {LoadingSpinner, Toaster} from "@matillion/component-library"
import {useCallback, useState} from "react";

export default function Loader() {
  return <LoadingSpinner />
}

/**
 * This hook can be used when you want to have some long running promise that has a loading state.
 *
 * It will handle updating a loading state value and a result state value
 */
export function useLoadableState(options = {}) {
  let [loading, setLoading] = useState(options.initialLoading || false)
  let [result, setResult] = useState(options.initialResult || null)
  let { makeToast } = Toaster.useToaster()
  let setErrorMessage = useCallback(
      (message) => {
        if (message !== "") makeToast({title: 'Error', message: message, type: 'error'})
      },
      [makeToast]
  )
  let load = useCallback(async (loader)=>{
    if(!setErrorMessage) { console.log("WHAT"); return }
    setLoading(true)
    try {
      let value = await loader({setErrorMessage, setResult})
      if (value !== undefined) setResult(value)
    } catch(e) {
      setErrorMessage(e.message)
    }
    setLoading(false)
  }, [setErrorMessage])
  return {load, loading, result}
}