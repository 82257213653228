function ExpressionViewer({x}) {
  let type = x.expressionType
  if(type === "ColumnExpression") {
    return <SimpleJsonViewer data={x.column} />
  }
  return <SimpleJsonViewer data={{...x, expressionType: null}} />
}

function isEmpty(x) {
  return x === null || x === undefined || (Array.isArray(x) && (x.length === 0 || x.every(isEmpty)))
}

function JsonFieldViewer({x}) {
  if(Array.isArray(x)) {
    return <div>
      {x.map((y, i)=><JsonFieldViewer key={i} x={y} />)}
    </div>
  }
  if(typeof(x) === "object") {
    return <SimpleJsonViewer data={x} />
  }
  return JSON.stringify(x)
}

export default function SimpleJsonViewer({data}) {
  if(data.expressionType) return <ExpressionViewer x={data} />
  let keys = Object.keys(data).filter(x=>!isEmpty(data[x]))
  if(keys.length === 0) return null
  if(keys.length === 1) {
    return <JsonFieldViewer x={data[keys[0]]} />
  }
  if(keys.length === 2 && data.value && data.alias) {
    return <SimpleJsonViewer data={{...data.value, alias: data.alias}} />
  }
  let stuff = keys.map(key=>{
    return <div key={key}>
      <div>{key}</div>
      <JsonFieldViewer x={data[key]} />
    </div>
  })
  return <div style={{display: 'flex', flexDirection: 'row'}}>
    {stuff}
  </div>
}