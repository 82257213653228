import {JobTree, UiExport} from "../../../Types/Exports";
import React, {useCallback, useEffect, useState} from "react";
import JobNameViewer from "../../UIExportData/JobNameViewer";
import {getName} from "../../../Types/JobObjectHelper";
import Loader from "../../../Common/Loader";
import {getInitialState, JobState, processSql, SetState, SqlComponentState, State} from "./ComponentUpdateState";
// @ts-ignore
import {Button} from "@matillion/component-library";
import {Tooltip} from "@material-ui/core";
import {inOrder} from "../../../Types/ComponentIterator";
import {useApi} from "@matillion/octo-react-util";
import {Api} from "../../../Api/Api";


function ComponentStateViewer({process, componentState: {component, sanitizedSql, loading, error, processed}}: {process: any, componentState: SqlComponentState}) {
  let [open, setOpen] = useState(false)
  let toggleOpen = useCallback(()=>setOpen(o=>!o), [setOpen])
  let processMe = useCallback(()=>process(component.id), [process, component])
  return <div style={{display: "flex", flexDirection: "column"}} onClick={toggleOpen}>
    <div style={{display: "flex", flexDirection: "row"}}>
      {getName(component)}
      {loading || processed || error ? null : <Button onClick={processMe}>Process Comp</Button>}
      {error ? <Tooltip title={error}><span style={{color: "red"}}>X</span></Tooltip>: null}
      {loading ? <Loader /> : null}
      {processed ? <span style={{color: "green"}}>Y</span> : null}
    </div>
    {open ? <div style={{whiteSpace: "pre-wrap", fontSize: '10px', fontFamily: 'monospace'}}>{sanitizedSql}</div> : null}
  </div>
}

function JobStateViewer({jobTree, process, processJob, jobState: {originalJob, componentStates}}: {process: any, processJob: any, jobTree: JobTree, jobState: JobState}) {
  let processMe = useCallback(()=>processJob(originalJob.id), [processJob, originalJob])
  let processComp = useCallback((compId)=>process(compId, originalJob.id), [originalJob, process])
  let canProcess = true//Object.values(componentStates).some(x=>!(x.loading || x.processed || x.error))
  return <>
    <JobNameViewer jobId={originalJob.id} jobsTree={jobTree} />
    {canProcess ? <Button onClick={processMe}>Process Job</Button>: null}
    <div style={{display:"flex", flexDirection:"column", paddingLeft: "1em"}}>
      {Object.values(componentStates).map(componentState=><ComponentStateViewer process={processComp} componentState={componentState} key={componentState.component.id} />)}
    </div>
  </>
}


export function UiExportViewer({fileData, loadState, setLoadState}: {fileData: UiExport, loadState: State, setLoadState: SetState<State | null>}) {
  let api = useApi<Api>()
  let process = useCallback((componentId, jobId, ls, sls)=>{
    return processSql(api, jobId, componentId, ls, sls)
  }, [api])
  let simpleProcess = useCallback((compId, jobId)=>process(compId, jobId, loadState, setLoadState),
    [loadState, setLoadState, process])
  let processJob = useCallback(async (jobId)=>{
    let ls = loadState
    let promise = Promise.resolve()
    let cheatUpdate = (fun: (s: State)=>State)=>{
      promise = promise.then(()=>new Promise((resolve)=>{
        setLoadState(x=> {
          let result = x ? fun(x) : null
          ls = result || ls
          resolve()
          return result
        })
      }))
    }
    await inOrder(loadState.jobs[jobId].originalJob, async ({comp}) => {
      let state = loadState.jobs[jobId].componentStates[comp.id]
      if (state) {
        await promise
        await process(comp.id, jobId, ls, cheatUpdate)
      }
    })
  }, [process, loadState, setLoadState])
  let processAll = useCallback(async ()=>{
    for(let jobId of Object.keys(loadState.jobs)) {
      await processJob(jobId)
    }
  }, [loadState, processJob])
  useEffect(()=>{
    setLoadState(getInitialState(fileData))
  }, [fileData, setLoadState])
  if(!fileData || fileData.dbEnvironment !== "snowflake") return null;
  return <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
    <Button onClick={processAll}>Process All</Button>
    Transformation Jobs:
    <ul>
      {fileData.transformationJobs.map(t=>{
        let jobState = loadState.jobs[t.id]
        return <div key={t.id}>
          {jobState ? <JobStateViewer processJob={processJob} process={simpleProcess} jobTree={fileData.jobsTree} jobState={jobState} /> : <Loader />}
        </div>
      })}
    </ul>
  </div>
}