import {useCallback, useContext, useState} from "react";
import {Button, Typography} from "@matillion/component-library";
import {ModalContext} from "../../Common/ModalHelper";

const LocalStorageKey = "saved_snippets"

function getData() {
  let json = window.localStorage.getItem(LocalStorageKey)
  try {
    if (json) {
      let data = JSON.parse(json)
      if(Array.isArray(data)) {
        return data.filter(x=>typeof(x) === "object" && x.name && x.text)
      }
    }
  } catch(e) {}
  return []
}

function eq(a, b) {
  return a.name === b.name && a.text === b.text
}

function useItems() {
  let [items, setItems] = useState(getData())
  let refresh = useCallback(()=>setItems(getData()), [setItems])
  return {items, refresh}
}

function saveData(data) {
  let oldData = getData()
  if(oldData.some(x=>eq(x, data))) return
  let newData = [
    ...oldData,
    data
  ]
  window.localStorage.setItem(LocalStorageKey, JSON.stringify(newData))
}

function LoadItem({itemData, setData, refresh}) {
  let loadCallback = useCallback(()=>setData(itemData), [itemData, setData])
  let deleteData = useCallback(()=>{
    let newItems = getData().filter(x=>!eq(x, itemData))
    window.localStorage.setItem(LocalStorageKey, JSON.stringify(newItems))
    refresh()
  }, [refresh, itemData])
  return <div style={{display: 'flex', flexDirection: "row"}}>
    <span style={{width: "250px"}}>{itemData.name}</span>
    <Button text="Load" onClick={loadCallback} size="sm"/>
    <Button text="Delete" onClick={deleteData} size="sm"/>
  </div>
}

function LoadSelector({refresh: refreshParent, setData, close}) {
  //This is necessary because this isn't a normal child component as it goes through the ModalContext, so doesn't update
  // when its parent updates
  let {items, refresh: refreshSelf} = useItems()
  let modal = useContext(ModalContext)
  let setDataCallback = useCallback((newData)=>{
    setData(newData)
    close()
    modal.notify(`Loaded data ${newData.name}`)
  }, [setData, close, modal])
  let refresh = useCallback(()=>{
    refreshSelf()
    refreshParent()
  }, [refreshParent, refreshSelf])
  return <>
    {items.map((data)=><LoadItem key={data.name} itemData={data} setData={setDataCallback} refresh={refresh}/> )}
  </>
}

function SaveButton({data, refresh}) {
  let modal = useContext(ModalContext)
  let onClick = useCallback((e)=>{
    e.preventDefault()
    saveData(data)
    refresh()
    modal.notify(`Saved data: ${data.name}`)
  }, [data, modal, refresh])
  return <Button text="Save Snippet" onClick={onClick} />
}

function LoadButton({items, refresh, setData}) {
  let modal = useContext(ModalContext)
  let open = useCallback(()=>{
    modal.open(({close})=>({
      titleSlot: <Typography>Load Snippet</Typography>,
      contentSlot: <LoadSelector {...{refresh, setData, close}} />,
      actionSlot: <></>
    }))
  }, [modal, refresh, setData])
  return items.length > 0 ? <Button onClick={open} text="Load Snippet" /> : null
}

export default function SaveLoad({data, setData}) {
  let [items, setItems] = useState(getData())
  let refresh = useCallback(()=>setItems(getData()), [setItems])
  return <>
    <SaveButton data={data} refresh={refresh} />
    <LoadButton setData={setData} items={items} refresh={refresh}  />
  </>
}