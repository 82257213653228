import {TransformationComponent, TransformationJob} from "./Transformation";
import {Value} from "./JobTypes";

interface CompData {
  comp: TransformationComponent,
  remainingInputs: number,
  inputs: number[]
  outputs: number[]
}
type CompDataMap = Record<number, CompData>

export async function inOrder(job: TransformationJob, fun: (data: CompData)=>(void | Promise<void>)) {
  let queue: number[] = []
  let map: CompDataMap = {}
  Object.values(job.components).forEach(comp=>{
    map[comp.id] = {
      comp,
      remainingInputs: 0,
      inputs: [],
      outputs: []
    }
  })
  Object.values(job.connectors).forEach(conn=>{
    map[conn.sourceID].outputs.push(conn.targetID)
    map[conn.targetID].remainingInputs++
    map[conn.targetID].inputs.push(conn.sourceID)
  })
  Object.values(map).forEach(comp=>{
    if(comp.remainingInputs === 0) {
      queue.push(comp.comp.id)
    }
  })
  while(queue.length > 0) {
    let removed = queue.shift()
    if(removed) {
      await fun(map[removed])
      map[removed].outputs.forEach(c=>{
        map[c].remainingInputs--
        if(map[c].remainingInputs === 0) {
          queue.push(c)
        }
      })
    } else {
      return
    }
  }
}

export function forAllParameterValues(job: TransformationJob, forVal: (val: Value, comp: TransformationComponent)=>void) {
  Object.values(job.components).forEach(comp=>{
    Object.values(comp.parameters).forEach(param=>{
      Object.values(param.elements).forEach(elem=>{
        Object.values(elem.values).forEach(value=>{
          forVal(value, comp)
        })
      })
    })
  })
}