import React, {useCallback, useEffect, useState} from "react";
import Styles from "./JobViewer.module.css"
import {Card, CardContent, CardHeader, Radio} from "@material-ui/core";
import SimpleJsonViewer from "./SimpleJsonViewer";

function ComponentViewer({component}) {
  let {name} = component
  let newComp = {...component, sources: null}
  return <div>
    COMPONENT {name}
    <SimpleJsonViewer data={newComp} />
  </div>
}

function Lines({position, maxPosition}) {
  if(position === 0) {
    if(maxPosition <= 1) {
      return <div className={Styles.horizontal}/>
    } else {
      return <React.Fragment>
        <div className={Styles.horizontal}/>
        <div className={Styles.vertical}/>
      </React.Fragment>
    }
  } else {
    if(position + 1 === maxPosition) {
      return <React.Fragment>
        <div className={Styles.horizontal}/>
        <div className={Styles.topVertical}/>
      </React.Fragment>
    } else {
      return <React.Fragment>
        <div className={Styles.horizontal}/>
        <div className={Styles.fullVertical}/>
      </React.Fragment>    }
  }
}

function TransformationJobSourceViewer({component, visible, setVisible}) {
  let children = component.sources.map((c, i)=> <div style={{display: 'flex', flexDirection: 'row'}}>
    <TransformationJobSourceViewer key={i} component={c} visible={visible} setVisible={setVisible} />
    <Lines position={i} maxPosition={component.sources.length} />
  </div>)
  let onClick = useCallback((e)=>{
    e.preventDefault()
    e.stopPropagation()
    setVisible(component)
  }, [component, setVisible])
  let className = visible === component ? Styles.selected : ""
  return <div style={{display: 'flex', flexDirection: 'row', marginLeft: '1px', flexWrap: 'nowrap'}}>
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
      {children}
    </div>
    {children.length > 0 ? <div className={Styles.horizontal}/> : null}
    <div style={{height: 'fit-content'}} className={className} onClick={onClick}>
      <Radio checked={visible === component} size="small" />
      {component.name}
    </div>
  </div>
}

function TransformationJobViewer({component, sql}) {
  let [visible, setVisible]  = useState(null)
  useEffect(()=>{
    setVisible(null)
  }, [component])
  return <div>
    <pre style={{whiteSpace: "pre-wrap", fontSize: "8px"}} >{sql}</pre>
    <Card>
      <CardHeader title="Components" />
      <CardContent>
        <TransformationJobSourceViewer component={component} visible={visible} setVisible={setVisible} />
      </CardContent>
    </Card>
    {visible ? <ComponentViewer component={visible} />  : null }
  </div>
}

function OrchestrationJobViewer({component, sql}) {
  return <div>
    <pre style={{whiteSpace: "pre-wrap", fontSize: "8px"}} >{sql}</pre>
    <ComponentViewer component={component} />
  </div>
}

export function JobViewer({component, sql}) {
  return component.sources ?
    <TransformationJobViewer {...{component, sql}} /> :
    <OrchestrationJobViewer {...{component, sql}}/>
}