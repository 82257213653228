import {useCallback, useState} from "react";
import Loader, {useLoadableState} from "../../Common/Loader";
import {Button} from "@matillion/component-library";
import {Card, CardContent, CardHeader, TextField} from "@material-ui/core";
import ParsedCodeMirror from "../../CodeMirror/ParsedCodeMirror";
import ExportResult from "../../ExportResult/ExportResult";
import SaveLoad from "./SaveLoad";
import DownloadButtons from "../../ExportResult/DownloadButtons";
import raw from "raw.macro";
import Styles from "./AppContents.module.css"
import {useApi} from "@matillion/octo-react-util";

const defaultText = raw("./DemoSQL.sql")

export function AppContents() {
  let api = useApi()

  let [{name, text}, setData] = useState({name: "IMPORT_Test", text: defaultText})
  let setNameCallback = useCallback((e) => setData(x => ({...x, name: e.target.value})), [setData])
  let setText = useCallback((newText) => setData(x => ({...x, text: newText})), [setData])

  let {loading, result, load} = useLoadableState({})

  let loadText = useCallback((e) => {
    e.preventDefault()
    load(({setErrorMessage})=>api.generateExport({request: {name, sql: text}, setErrorMessage}))
  }, [load, api, name, text])

  return (
      <>
        <div className={Styles.MainSection}>
          <Card style={{width: '100%', margin: '5px', flexGrow: 1}}>
            <CardHeader title="Convert SQL"/>
            <CardContent>
              <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                <div style={{flexGrow: 1}}>
                  <TextField label='Job Name' value={name} onChange={setNameCallback}/>
                  <ParsedCodeMirror text={text} setText={setText} errorData={result?.result?.errorData}/>
                </div>
              </div>
            </CardContent>
          </Card>
          {result ? <ExportResult result={result.result} sql={result.sql} name={result.name}/> : null}
        </div>
        <div className={Styles.ButtonBar}>
          <div className={Styles.ButtonBarLeftSection}>
            <SaveLoad setData={setData} data={{text, name}}/>
            <Button alt="primary" text={loading ? "Loading Result ..." : "Transmogrify!"} onClick={loadText}
                    disabled={loading}/>
            {loading ? <Loader/> : null}
          </div>
          {result ?
            <div className={Styles.ButtonBarRightSection}>
              <DownloadButtons name={name} result={result.result}/>
            </div> : null}
        </div>
      </>
    )
}