import Styles from "../AppContents.module.css";
import {Card, CardContent, CardHeader, Input} from "@material-ui/core";
import DownloadButtons from "../../../ExportResult/DownloadButtons";
import {useCallback, useEffect, useState} from "react";
// @ts-ignore
import {Button} from "@matillion/component-library";
import {UiExport} from "../../../Types/Exports";
import {UiExportViewer} from "./UiExportViewer";
import {getInitialState, State} from "./ComponentUpdateState";
import {GenerateResult} from "../../../Api/Api";
import {convertToApiExport} from "../../../Types/ExportConverter";

function FileLoader({setFileData}: {setFileData: (fileData: UiExport)=>void}) {
  let onChange = useCallback((e)=>{
    let files = e.target.files
    let reader = new FileReader()
    reader.addEventListener("load", (x)=>{
      let result = x?.target?.result as string
      try {
        let data = JSON.parse(result) as UiExport
        setFileData(data)
      } catch(e) {
        console.error(e)
      }
    })
    reader.readAsText(files[0])
  }, [setFileData])
  return <Input type="file" onChange={onChange}/>
}

export default function SqlComponentUpdate() {
  let [result, setResult] = useState<GenerateResult | null>(null)
  let [fileData, setFileData] = useState<UiExport | null>(null)
  let [loadState, setLoadState] = useState<State | null>(null)
  useEffect(()=>setLoadState(fileData ? getInitialState(fileData) : null), [fileData, setLoadState])
  let produceResult = useCallback(()=>{
    if(!fileData || !loadState) return
    let newData = {
      ...fileData,
      transformationJobs: [...(fileData?.transformationJobs || [])]
    }
    Object.values(loadState.jobs).forEach(d=>{
      let index = newData.transformationJobs.findIndex(c=>c.id === d.currentJob.id)
      newData.transformationJobs[index] = d.currentJob
    })
    setResult({
      name: "Converted Jobs",
      sql: "Lots of SQL",
      result: {
        exportContainer: convertToApiExport(newData)
      }
    })
  }, [setResult, fileData, loadState])
  return <>
    <div className={Styles.MainSection}>
      <Card style={{width: '100%', margin: '5px', flexGrow: 1}}>
        <CardHeader title="Convert SQL"/>
        <CardContent>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <FileLoader setFileData={setFileData} />
            {fileData && loadState ? <UiExportViewer fileData={fileData} loadState={loadState} setLoadState={setLoadState} /> : null}
          </div>
        </CardContent>
      </Card>
    </div>
    <div className={Styles.ButtonBar}>
      <div className={Styles.ButtonBarLeftSection}>
        <Button onClick={produceResult}>Transmogrify</Button>
      </div>
      {result ?
        <div className={Styles.ButtonBarRightSection}>
          <DownloadButtons name={"ASD"} result={result.result}/>
        </div> : null}
    </div>
  </>
}