import {TransformationComponent, TransformationJob} from "../../Types/Transformation";
import {getName, getSingleParameter, maxTJobId, setName} from "../../Types/JobObjectHelper";
import {unsanitizeTableName} from "./RewireSanitizer";

export const tableInputImplementationId = 1354890871

function reIdComponents(job: TransformationJob, start: number) {
  let map: Record<number, number> = {}
  Object.values(job.components).forEach(x=>{
    let oldId = x.id
    let newId = start++
    map[x.id] = newId
    x.id = newId
    delete job.components[oldId]
    job.components[newId] = x
  })
  Object.values(job.connectors).forEach(x=>{
    let oldId = x.id
    x.id = start++
    x.sourceID = map[x.sourceID]
    x.targetID = map[x.targetID]
    delete job.connectors[oldId]
    job.connectors[x.id] = x
  })
}

function removeComponent(job: TransformationJob, componentId: number) {
  delete job.components[componentId]
  Object.values(job.connectors).forEach(c=>{
    if(c.sourceID === componentId || c.targetID === componentId) {
      delete job.connectors[c.id]
    }
  })
}

/**
 * Work out what the table inputs are and remove those from the job so that we can replace them with source components
 * in the other job
 * @param job
 */
function getTableMap(job: TransformationJob): Record<string, number[]> {
  let tableInputs = Object.values(job.components)
    .filter(x=>x.implementationID === tableInputImplementationId)
  let map: Record<string, number[]> = {}
  tableInputs.forEach(x=>{
    let unsanitizedTableName = getSingleParameter(x, 2)
    let tableName = unsanitizeTableName(unsanitizedTableName)
    //This table input was a component so let's remove it so we can rewire things
    if(tableName !== unsanitizedTableName) {
      let connector = Object.values(job.connectors).find(c => c.sourceID === x.id)
      if (!connector) throw Error(`Unable to find connector for table input ${getName(x)}`)
      let arr = map[tableName] || []
      arr.push(connector.targetID)
      map[tableName] = arr
      removeComponent(job, x.id)
    }
  })
  return map
}

function getSize(job: TransformationJob) {
  let mX = Object.values(job.components).map(x=>x.x).reduce((p,c)=>Math.min(p,c))
  let MX = Object.values(job.components).map(x=>x.x).reduce((p,c)=>Math.max(p,c))
  let mY = Object.values(job.components).map(x=>x.y).reduce((p,c)=>Math.min(p,c))
  let MY = Object.values(job.components).map(x=>x.y).reduce((p,c)=>Math.max(p,c))
  return {width: MX - mX, height: MY - mY, mX, mY}
}

function moveComponents(oldComponent: TransformationComponent, newJob: TransformationJob, job: TransformationJob) {
  let size = getSize(newJob)
  Object.values(job.components).forEach(comp=>{
    if(comp.id !== oldComponent.id) {
      if(comp.x > oldComponent.x) {
        comp.x += size.width
      }
      if(comp.y > oldComponent.y) {
        comp.y += size.height
      }
    }
  })
  Object.values(newJob.components).forEach(comp=>{
    comp.x = (comp.x - size.mX) + oldComponent.x
    comp.y = (comp.y - size.mY) + oldComponent.y
  })
}

export function wireInNewComponents(oldComponent: TransformationComponent, newJob: TransformationJob, job: TransformationJob) {
  if(Object.keys(newJob.components).length === 1) {
    console.log("Only one component, not rewiring", oldComponent, newJob, job)
    return
  }
  let c = job.components[oldComponent.id] || oldComponent
  let maxId = maxTJobId(job)
  reIdComponents(newJob, maxId + 1)
  let outputs = Object.values(job.connectors).filter(x=>x.sourceID === oldComponent.id)
  removeComponent(job, oldComponent.id)
  let connectionMap = getTableMap(newJob)
  moveComponents(c, newJob, job)
  Object.values(newJob.components).forEach(c=>job.components[c.id] = c)
  Object.values(newJob.connectors).forEach(c=>job.connectors[c.id] = c)
  maxId = maxTJobId(job)
  Object.keys(connectionMap).forEach(tableName=>{
    let componentName = unsanitizeTableName(tableName)
    let component = Object.values(job.components).find(c=>getName(c) === componentName)
    if(!component) throw Error(`Unable to find component to match input ${componentName}`)
    let compId = component.id
    connectionMap[tableName].forEach(outputId=>{
      let newConnector = {
        id: ++maxId,
        sourceID: compId,
        targetID: outputId
      }
      job.connectors[newConnector.id] = newConnector
    })
  })
  let lastComponent = Object.values(newJob.components).find(c=>!Object.values(newJob.connectors).some(con=>con.sourceID === c.id))!
  setName(lastComponent, getName(oldComponent))
  outputs.forEach(x=>{
    x.sourceID = lastComponent.id
    job.connectors[x.id] = x
  })
}