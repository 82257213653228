import {ApiExport} from "../Types/Exports";
import {Metadata} from "../Types/Metadata";
import {BaseApi} from "@matillion/octo-react-util";

export interface GenerateResultDetails {
  exportContainer?: ApiExport | null,
  error?: string | null,
  errorData?: any[] | null
  metadata?: Metadata
}

export interface GenerateResult {
  name: string,
  sql: string,
  result: GenerateResultDetails,
}

export interface GenerateRequest {
  sql: string,
  name: string,
  context?: {
    metadata?: {
      metadata?: Metadata,
      defaultDatabase?: string,
      defaultSchema?: string
    }
  }
}

interface GenerateExportArgs {
  request: GenerateRequest,
  setErrorMessage: (error: string)=>void,
}

export class Api extends BaseApi {
  async generateExport({request, setErrorMessage}: GenerateExportArgs ): Promise<GenerateResult | null> {
    try {
      let response = await this.fetch(`api/generate?`, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          "Content-Type": "application/json"
        }
      })
      setErrorMessage("")
      if (response.status === 200) {
        let json = await response.json()
        return {sql: request.sql, result: json, name: request.name}
      } else {
        setErrorMessage("An error has occurred when uploading the SQL. Status code: " + response.status + ". Please try again.")
      }
    } catch {
      setErrorMessage("Please try reloading the page.")
    }
    return null
  }
}