import {Variable} from "../../Types/JobTypes";
import {TransformationJob} from "../../Types/Transformation";
import {forAllParameterValues} from "../../Types/ComponentIterator";

export type KnownVariableMap = Record<string, string>

export function buildKnownVariableMap(vars: Record<string, Variable>): KnownVariableMap {
  let map: KnownVariableMap = {}
  Object.values(vars).forEach(({definition, value})=>{
    let replacement = null
    if(definition.type === "DECIMAL") {
      replacement = `1337${Math.round(Math.random() * 100000)}123`
    }
    if(replacement) {
      map[definition.name] = replacement
    }
  })
  return map
}

export function sanitizeVariables(knownVariables: KnownVariableMap, sql: String): string {
  return sql.replace(/\${(.*?)}/g, (substring, name)=>{
    return knownVariables[name] || `VARIABLE_REPLACEMENT_${name}_VAR_REPL_END`
  })
}

export function unsanitizeVariables(knownVariables: KnownVariableMap, job: TransformationJob) {
  forAllParameterValues(job, (value)=>{
    value.value = value.value.replace(/VARIABLE_REPLACEMENT_(.*?)_VAR_REPL_END/g, (substring, arg)=>`\${${arg}}`)
    Object.keys(knownVariables).forEach(varName=>{
      let varRepl = knownVariables[varName]
      if(value.value.includes(varRepl)) {
        value.value = varRepl.replace(varRepl, `\${${varName}}`)
      }
    })
  })
}