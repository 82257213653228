import {TransformationComponent, TransformationJob} from "../../../Types/Transformation";
import {UiExport} from "../../../Types/Exports";
import {getName, getSingleParameter} from "../../../Types/JobObjectHelper";
import {sanitizeTableSyntax, unsanitizeTables} from "../../UIExportData/RewireSanitizer";
import React, {ComponentState} from "react";
import {Api} from "../../../Api/Api";
import {tableInputImplementationId, wireInNewComponents} from "../../UIExportData/Rewirer";
import {Metadata, TableMetadata} from "../../../Types/Metadata";
import {buildKnownVariableMap, KnownVariableMap, unsanitizeVariables} from "../../UIExportData/RewireVariables";

export interface SqlComponentState {
  component: TransformationComponent,
  sql: string,
  sanitizedSql: string
  loading: boolean,
  error: string | null,
  processed: boolean
}

export interface JobState {
  originalJob: TransformationJob
  currentJob: TransformationJob,
  metadata: Metadata,
  varMap: KnownVariableMap,
  componentStates: {[componentId: number]: SqlComponentState}
}

export interface State {
  jobs: {[id: number]: JobState}
}

export function copyObject(obj: any): any {
  if(obj && typeof obj === "object") {
    if(Array.isArray(obj)) {
      return obj.map(x=>copyObject(x))
    } else {
      let copy: any = {}
      Object.keys(obj).forEach(key=>copy[key] = copyObject(obj[key]))
      return copy
    }
  } else {
    return obj
  }
}

export const sqlImplementationId = -1266674941
export const environmentDefault = "[Environment Default]"

function getTableInputMetadata(tInput: TransformationComponent): TableMetadata {
  let name = getName(tInput)
  let columns = Object.values(tInput.parameters[3].elements).map(e=>e.values[1].value)
  return {
    table: {
      name: `COMPONENT_TABLE_${name}`
    },
    columns: columns.map(col=>{
      return {name: col}
    })
  }
}

function getAllTableInputMeta(tJob: TransformationJob): TableMetadata[] {
  return Object.values(tJob.components)
    .filter(tComp=>tComp.implementationID === tableInputImplementationId)
    .map(tInput=>getTableInputMetadata(tInput))
}

export function getInitialState(fileData: UiExport): State {
  let state: State = {
    jobs: {}
  }
  fileData.transformationJobs.forEach(tJob=>{
    let varMap = buildKnownVariableMap(tJob.variables)
    state.jobs[tJob.id] = {
      originalJob: tJob,
      varMap,
      currentJob: copyObject(tJob) as TransformationJob,
      metadata: {tables: getAllTableInputMeta(tJob)},
      componentStates: {}
    }

    Object.values(tJob.components)
      .filter(tComp=>tComp.implementationID === sqlImplementationId)
      .filter(tComp=>Object.values(tJob.connectors).some(x=>x.targetID === tComp.id))
      .forEach(tComp=>{
        let sql = getSingleParameter(tComp, 2)
        state.jobs[tJob.id].componentStates[tComp.id] = {
          component: tComp,
          loading: false,
          error: null,
          sql,
          sanitizedSql: sanitizeTableSyntax(varMap, sql),
          processed: false
        }
      })
  })
  return state
}

export type SetState<X> = React.Dispatch<React.SetStateAction<X>>
export function updateJobState(jobId: number, setState: SetState<State>, update: (p: JobState)=>JobState) {
  setState(p=>({
    ...p,
    jobs: {
      ...p.jobs,
      [jobId]: update(p.jobs[jobId])
    }
  }))
}
export function updateCompState(jobId: number, componentId: number, setState: SetState<State>, update: (p: ComponentState)=>ComponentState) {
  updateJobState(jobId, setState, (jobState=>({
    ...jobState,
    componentStates: {
      ...jobState.componentStates,
      [componentId]: update(jobState.componentStates[componentId])
    }
  })))
}

export async function processSql(api: Api, jobId: number, componentId: number, state: State, setState: SetState<State>) {
  updateCompState(jobId, componentId, setState, x=>({...x, loading: true, error: null, processed: false}))
  let jobState = state.jobs[jobId]
  let compState = jobState.componentStates[componentId]
  console.log("Processing job", jobState)
  let response = await api.generateExport({
    request: {
      name: "Temp",
      sql: compState.sanitizedSql,
      context: {
        metadata: {
          metadata: jobState.metadata
        }
      }
    },
    setErrorMessage: (error: string)=>updateCompState(jobId, componentId, setState, c=>({...c, loading: false, error}))
  })
  if(response) {
    let {result} = response
    if(result.error) {
      updateCompState(jobId, componentId, setState, c=>({...c, loading: false, error: result.error}))
    } else if(result.exportContainer) {
      let job = result.exportContainer.objects[0].jobObject as TransformationJob
      updateJobState(jobId, setState, j=>{
        let newMeta = j.metadata
        if(result.metadata) {
          newMeta = copyObject(newMeta)
          newMeta.tables.push({
            table: {
              name: `COMPONENT_TABLE_${getName(compState.component)}`
            },
            columns: result.metadata.tables[0].columns
          })
        }
        unsanitizeTables(job)
        unsanitizeVariables(j.varMap, job)
        let newJobCopy = copyObject(j.currentJob) as TransformationJob
        wireInNewComponents(compState.component, copyObject(job), newJobCopy)
        return {...j, currentJob: newJobCopy, metadata: newMeta}
      })
      updateCompState(jobId, componentId, setState, c=>({...c, loading: false, processed: true}))
    }
  }
}
