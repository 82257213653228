import {Job} from "./JobTypes";
import {ApiExport, JobInfo, JobTree, UiExport} from "./Exports";
import {TransformationJob} from "./Transformation";
import {OrchestrationJob} from "./Orchestration";

function njo<J extends Job<any>>(j: Job<any>): J {
  let n = {...j} as any
  delete n.JobType
  return n as J
}

export function convertToUIExport(exportContainer: ApiExport): UiExport {
  let jobs = exportContainer.objects
  let jobInfos = jobs.map(x=>x.info)
  let transformationJobs = jobs.filter(x=>x.info.type === "TRANSFORMATION").map(x=>njo<TransformationJob>(x.jobObject))
  let orchestrationJobs = jobs.filter(x=>x.info.type === "ORCHESTRATION").map(x=>njo<OrchestrationJob>(x.jobObject))
  let result = {
    dbEnvironment: exportContainer.environment,
    version: exportContainer.version,
    jobsTree: {
      id: 12345,
      name: "ROOT",
      children: [],
      jobs: jobInfos
    },
    transformationJobs,
    orchestrationJobs,
    environments: [],
    variables: []
  }
  return result
}

type FlattenResult = {path: string[], info: JobInfo}[]
function flattenTree(pathSoFar: string[], jobsTree: JobTree): FlattenResult {
  let path = [...pathSoFar]
  if(jobsTree.name !== "ROOT") path.push(jobsTree.name)
  let result = jobsTree.jobs.map(info=>({path: path, info}))
  let children = jobsTree.children.flatMap(x=>flattenTree(path, x))
  return [...result, ...children]
}

export function convertToApiExport(uiExport: UiExport): ApiExport {
  let objects = flattenTree([], uiExport.jobsTree)
  let newObject = objects.map(o=>{
    let jobObject = (o.info.type === "TRANSFORMATION" ? uiExport.transformationJobs : uiExport.orchestrationJobs).find(x=>x.id === o.info.id) as any
    jobObject.JobType = o.info.type === "TRANSFORMATION" ? ".TransformationJob" : ".OrchestrationJob"
    return {...o, jobObject: jobObject as Job<any>}
  })
  return {
    environment: uiExport.dbEnvironment,
    version: uiExport.version,
    objects: newObject
  }
}