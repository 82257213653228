import {Controlled as CodeMirror} from "react-codemirror2"
import {useCallback, useEffect, useState} from "react";

function shPos(x) {
  return {line: x.line - 1, ch: x.ch}
}

export default function ParsedCodeMirror({text, setText, errorData}) {
  let [marks, setMarks] = useState({errorData: null, marks: []})
  let [editor, setEditor] = useState(null)
  let setEditorC = (e)=>{
    e.setSize("100%", "100%")
    setEditor(e)
  }
  useEffect(()=>{
    if(!editor || !errorData || marks.errorData === errorData) return
    marks.marks.forEach(x=>x.clear())
    let newMarks = errorData.map(err=>{
      return editor.markText(shPos(err.from), shPos(err.to), {
        css: 'color: red',
        attributes: {
          title: err.message
        }
      })
    })
    setMarks({errorData, marks: newMarks})
  }, [marks, setMarks, errorData, editor])
  let onBeforeChange = useCallback((editor, codemirror, value)=>{
    marks.marks.forEach(x=>x.clear())
    setMarks({errorData: marks.errorData, marks: []})
    setText(value)
  }, [setText, marks, setMarks])
  return <CodeMirror
    editorDidMount={(e)=>setEditorC(e)}
    value={text}
    onBeforeChange={onBeforeChange}
    options={{
      lineNumbers: true
    }}
  />
}