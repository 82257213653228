function findJobName({jobsTree, jobId}) {
  let item = jobsTree.jobs.find(x=>x.id === jobId)
  if(item) {
    return item.name
  } else {
    for(let child of jobsTree.children) {
      let name = findJobName({jobsTree: child, jobId})
      if(name) return name
    }
  }
  return `[${jobId}] Unknown name`
}

export default function JobNameViewer({jobsTree, jobId}) {
  let name = findJobName({jobsTree, jobId})
  return name
}