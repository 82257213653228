import MetlInstallButton from "./MetlInstallButton";
import DownloadButton from "./DownloadButton";
import {convertToUIExport} from "../Types/ExportConverter";

export default function DownloadButtons({name, result}) {
    let {error} = result
    if(error) return null

    let {exportContainer} = result
    let uiExport = convertToUIExport(exportContainer)

    return(
    <>
        <MetlInstallButton uiExport={uiExport}/>
        <DownloadButton object={uiExport} name={`${name}_UI.json`} title="Download Job (UI Export)" alt="primary"/>
        <DownloadButton object={exportContainer} name={`${name}_API.json`} title="Download Job (API Export)"
                        alt="secondary"/>
    </>)
}


