import React, { createContext, useCallback, useEffect, useState } from "react";
import Config from "../Config"

export const MetlCommunicationContext = createContext({metlInstance: null, comms: null})

class MetlComms {
    constructor(iframe) {
        this.iframe = iframe
        this.baseId = "UNDERSTANDER_" + Math.floor(Math.random() * 1000000000) + "_"
        this.counter = 0
        this.callbacks = {}
        this.listener = (e) =>{
            //console.log("Response received", e)
            if(e.data && e.data.id && this.callbacks[e.data.id]) {
                this.callbacks[e.data.id](e.data.data)
            }
        }
    }

    register() {
        window.addEventListener("message", this.listener)
    }

    unregister() {
        window.removeEventListener("message", this.listener)
    }

    post(type, data, callback) {
        console.debug("Metl Message Sent", type, data)
        let id = this.baseId + (this.counter++)
        this.callbacks[id] = callback
        this.iframe.contentWindow.postMessage({type, id, data}, '*')
    }
}

export function MetlCommunicationWrapper({children}) {
    let load = useCallback((e)=>{
        let iframe = e.target
        setComms(new MetlComms(iframe))
        console.log("Communication iframe loaded", e)
    }, [])

    let [comms, setComms] = useState(null)
    let [metlInstance, setMetlInstance] = useState(null)
    useEffect(()=>{
        if(comms) {
            comms.register()
            let intervalId = setInterval(()=>{ if(!metlInstance) { comms.post("IsMtlnThere", {}, setMetlInstance) }}, 5000)
            return ()=>{
                clearInterval(intervalId)
                comms.unregister()
            }
        }
    }, [comms, metlInstance])
    let result = {comms, metlInstance}
    return <React.Fragment>
        <iframe title="Metl Communication" src={Config.communicationUrl} style={{display: 'none'}} onLoad={load} />
        <MetlCommunicationContext.Provider value={result}>
            {children}
        </MetlCommunicationContext.Provider>
    </React.Fragment>
}